import { useState } from "react";
import axios from "axios";

const CreatePassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [alert, setAlert] = useState("");

  const sendPassword = async (e) => {
    // on récupère l'id de l'utilisateur dans l'url
    const url = window.location.href;
    const user_id = url.split("/").pop();

    e.preventDefault();
    if (password === passwordConfirm && user_id) {
      console.log("password : ", password);
      // Send password to the server
      const connection = await axios.post(
        "https://sushinari.fr/api/account_create",
        {
          password: password,
          id: user_id,
        }
      );
      console.log(connection);
      if (connection.data.success) {
        // Rediriger vers la page de connexion
        window.location.href = "/login";
      } else {
        setAlert(connection.data.message);
      }
    } else {
      setAlert("Les mots de passe ne correspondent pas");
    }
  };

  return (
    <div className="create-password">
      <h1>Création de votre mot de passe</h1>
      <p>
        Bienvenue sur Sushinari ! Pour créer votre mot de passe, veuillez saisir
        compléter les champs ci-dessous.
      </p>
      <form className="create-password-form">
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />
        <label htmlFor="password">Mot de passe</label>
        <input type="password" id="password" name="password" required />
        <label htmlFor="password-confirm">Confirmez votre mot de passe</label>
        <input
          type="password"
          id="password-confirm"
          name="password-confirm"
          required
        />
        <div className="alert">{alert}</div>
        <button type="submit" onClick={sendPassword}>
          Valider+
        </button>
      </form>
    </div>
  );
};

export default CreatePassword;

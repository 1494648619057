import { useState } from "react";
import axios from "axios";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState("");

  const logUserIn = async (event) => {
    console.log("logUserIn");
    event.preventDefault();
    const response = await axios.post("https://sushinari.fr/api/login", {
      username: username,
      password: password,
    });
    if (response.data.success) {
      setAlert("Connexion réussie.");
      localStorage.setItem("token", response.data.token);
      window.location.href = "/dashboard";
    } else {
      setAlert("Nom d'utilisateur ou mot de passe incorrect.");
    }
  };

  return (
    <div className="Login">
      <div className="Login_logo"></div>
      <div className="Login_zone">
        <h1 className="Login_title">Connexion</h1>
        <form className="Login_form">
          <input
            type="text"
            className="Login_form_input"
            placeholder="Adresse email"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="Login_form_input"
            placeholder="Mot de passe"
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="Login_alert">{alert}</p>
          <button className="Login_form_button" onClick={logUserIn}>
            Se connecter
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

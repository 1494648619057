import { useCallback, useEffect, useState } from "react";
import Menu from "../Components/Menu";
import "./ClickandCollect.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../Stripe/CheckoutForm";
import axios from "axios";
import { DNA } from "react-loader-spinner";

const ClickandCollect = () => {
  const stripePromise = loadStripe(
    "pk_test_51QPgRNKCJPd2Gpe5AfDljv6uqlHr430RNMz8vn4J0qOQfpefgPmxK3jp2ym6cZy67cJ7pm7BsOmuLiCCNn3Xx2DE00rsqAOGp1"
  );
  const [shopChoosen, setShopChoosen] = useState(null);
  const [cart, setCart] = useState([
    {
      box: 0,
      quantity: 0,
      name: "Plateau Sushi",
      price: 20,
      image: "sushi.jpg",
      content: [
        "6 sushis",
        "6 makis",
        "6 california rolls",
        "6 spring rolls",
        "Salade Wakamé",
        "Sauce soja",
        "Gingembre",
        "Wasabi",
      ],
    },
  ]);
  const [magasin, setMagasin] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalService, setTotalService] = useState(0);
  const [custommer, setCustommer] = useState({
    command_id: 0,
    name: "",
    surname: "",
    email: "",
    phone: "",
    step: 0,
  });
  const [time_slots, setTime_slots] = useState([]);
  const [time_slot, setTime_slot] = useState(null);
  const [stripe, setStripe] = useState({
    card: "",
    exp: "",
    cvc: "",
    name: "",
  });
  const [formAlert, setFormAlert] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const addToCart = (box) => {
    setCart([...cart, cart[box].quantity++]);
  };
  const articleMore = (box) => {
    let newCart = [...cart];
    newCart[box].quantity++;
    setCart(newCart);
  };
  const articleLess = (box) => {
    let newCart = [...cart];
    newCart[box].quantity--;
    setCart(newCart);
  };
  const load_magasins = useCallback(async () => {
    const response = await axios.post("https://sushinari.fr/api/magasins", {});
    if (response.data.success) {
      setMagasin(
        response.data.magasins
          .map((a_magasin) => ({
            id: a_magasin.id,
            name: a_magasin.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    } else {
      setFormAlert("Erreur lors du chargement des magasins.");
    }
  }, []);
  const load_time_slots = useCallback(async () => {
    const response = await axios.post("https://sushinari.fr/api/time_slots", {
      magasin: shopChoosen,
    });
    if (response.data.success) {
      setTime_slots(response.data.time_slots);
    } else {
      setFormAlert("Erreur lors du chargement des créneaux horaires.");
    }
  }, [shopChoosen]);
  const load_articles = useCallback(async () => {
    console.log("load_articles");

    const response = await axios.post("https://sushinari.fr/api/articles", {
      type: "dispo_magasins",
    });

    if (response.data.success) {
      console.log("articles chargés :", response.data.articles);
      setCart(
        response.data.articles.map((article) => ({
          ...article,
          quantity: 0,
          id: article.id,
          ref: article.ref ? article.ref : "",
          description: article.description ? article.description : "",
          image: article.image ? article.image : "sushi.jpg",
          price: article.price ? article.price : 0,
          name: article.name ? article.name : "Article sans nom",
        }))
      );
    } else {
      setFormAlert("Erreur lors du chargement des articles.");
    }
  }, []);
  const verifyCustommer = async () => {
    if (custommer.name.match(/^[a-zA-Z]+$/) === null) {
      setFormAlert("Nom invalide.");
    } else if (custommer.surname.match(/^[a-zA-Z]+$/) === null) {
      setFormAlert("Prénom invalide.");
    } else if (
      custommer.email.match(/^[a-zA-Z\.\-]+@[a-zA-Z\.\-]+$/) === null
    ) {
      setFormAlert("Email invalide.");
    } else if (custommer.phone.match(/^[0-9]{10}$/) === null) {
      setFormAlert("Téléphone invalide.");
    } else if (time_slot === null) {
      setFormAlert("Veuillez choisir un créneau horaire.");
    } else {
      setCustommer({ ...custommer, step: 2 });
    }
  };
  const clientSecretAsk = useCallback(async () => {
    const response = await axios.post(
      "https://sushinari.fr/api/stripe_secret",
      {
        amount: (total + totalService) * 100,
        taxe: Math.round((total + totalService) * 0.021 * 100) / 100,
        serviceCharge: totalService,
        custommer: custommer,
      }
    );
    if (response.data.success) {
      console.log("CLIENT SECRET", response.data.client_secret);
      setClientSecret(response.data.client_secret);
    } else {
      setFormAlert("Erreur lors de la demande de paiement.");
    }
  }, [total, totalService, custommer]);
  const sendCommandeDatas = useCallback(async () => {
    console.log("sendCommandeDatas");
    const response = await axios.post(
      "https://sushinari.fr/api/create_commande",
      {
        custommer: custommer,
        cart: cart,
        total: total,
        totalService: totalService,
        taxe: Math.round((total + totalService) * 0.021 * 100) / 100,
        time_slot: time_slots.find(
          (a_time_slot) => a_time_slot.id === time_slot
        ),
      }
    );
    if (response.data.success) {
      console.log("commande envoyée");
      setCustommer({
        ...custommer,
        step: 3,
        command_id: response.data.command_id,
      });
    } else {
      setFormAlert("Erreur lors de l'envoi de la commande.");
    }
  }, [custommer, cart, total, totalService, time_slot]);

  useEffect(() => {
    load_articles();
  }, [load_articles]);
  useEffect(() => {
    load_magasins();
  }, [load_magasins]);
  useEffect(() => {
    setTotal(
      cart.reduce(
        (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
        0
      )
    );
    const totalItem = cart.reduce((acc, item) => acc + (item.quantity || 0), 0);
    if (totalItem > 0) {
      setTotalService(2);
    } else {
      setTotalService(0);
    }
  }, [cart]);
  useEffect(() => {
    if (shopChoosen !== null) {
      load_time_slots();
    } else {
      setTime_slots([]);
    }
  }, [shopChoosen, load_time_slots]);
  useEffect(() => {
    if (custommer.step === 2 && clientSecret === "") {
      clientSecretAsk();
    } else if (custommer.step === 0) {
      setFormAlert("");
    }
  }, [custommer.step, clientSecret, clientSecretAsk]);
  useEffect(() => {
    setClientSecret("");
  }, [total]);
  useEffect(() => {
    console.log(custommer.command_id);
    if (
      custommer.step === 3 &&
      (custommer.command_id === 0 ||
        custommer.command_id === null ||
        custommer.command_id === undefined)
    ) {
      sendCommandeDatas();
    }
  }, [custommer.step, custommer.command_id, sendCommandeDatas]);

  return (
    <div className="ClickandCollect">
      <Menu />
      <div className="ClickandCollect_screen">
        <div className="ClickandCollect_background">
          <div className="ClickandCollect_Title">
            <div className="ClickandCollect_Title_Fr">
              <h1>Click & Collect</h1>
            </div>
            <div className="ClickandCollect_Title_Jp">
              <p>クリック ＆ コレクト</p>
            </div>
          </div>
          <div className="ClickandCollect_Explication">
            <p>Commandez en ligne et récupérez votre commande en magasin.</p>
          </div>
        </div>
      </div>
      {shopChoosen === null && (
        <>
          <div className="ClickandCollect_Shops_List_title">
            <h2> Choisissez votre magasin </h2>
          </div>
          <div className="ClickandCollect_Shops_List">
            {magasin.map((a_magasin, index) => {
              return (
                <div
                  key={index}
                  className="ClickandCollect_Shop_case"
                  onClick={() => setShopChoosen(a_magasin.id)}
                >
                  <div className="ClickandCollect_Shop_case_title">
                    {a_magasin.name}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {shopChoosen !== null && custommer.step === 0 && (
        <>
          <div className="ClickandCollect_Shops_List_back_button_zone">
            <div
              className="ClickandCollect_Shops_List_back_button"
              onClick={() => setShopChoosen(null)}
            >
              Retour
            </div>
          </div>
          <div className="ClickandCollect_Shops_List_title">
            <h2> Choisissez votre plateau </h2>
          </div>
          <div className="ClickandCollect_Box_List">
            {console.log("CART :: ", cart)}
            {cart.map((item, index) => {
              if (item.name) {
                return (
                  <div key={index} className="ClickandCollect_Box_case">
                    <div className="ClickandCollect_Box_case_title">
                      {item.name}
                    </div>
                    <div className="ClickandCollect_Box_case_price">
                      {item.price}€
                    </div>
                    {item.image && (
                      <div
                        className="ClickandCollect_Box_case_image"
                        style={{
                          backgroundImage: `url('https://sushinari.fr/uploads/${item.image}')`,
                        }}
                      ></div>
                    )}
                    <div className="ClickandCollect_Box_case_description">
                      <p>
                        Description : <br />
                        <br />
                        {item.description}
                      </p>
                    </div>
                    {cart[index].quantity === 0 ? (
                      <div
                        className="ClickandCollect_Box_case_button"
                        onClick={() => addToCart(index)}
                      >
                        Ajouter au panier
                      </div>
                    ) : (
                      <div className="ClickandCollect_Box_case_number_of_box">
                        <div
                          className="ClickandCollect_Box_case_number_of_box_minus"
                          onClick={() => articleLess(index)}
                        >
                          -
                        </div>
                        <div className="ClickandCollect_Box_case_number_of_box_quantity">
                          {cart[index].quantity}
                        </div>
                        <div
                          className="ClickandCollect_Box_case_number_of_box_plus"
                          onClick={() => articleMore(index)}
                        >
                          +
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          {total > 0 && (
            <div
              className="ClickandCollect_Validation"
              onClick={() => setCustommer({ ...custommer, step: 1 })}
            >
              Passer la commande - {total + totalService} €
            </div>
          )}
        </>
      )}
      {custommer.step === 1 && (
        <div className="ClickandCollect_Custommer_info_zone">
          <div className="ClickandCollect_Custommer_info_validation_back_zone">
            <div
              className="ClickandCollect_Custommer_info_validation_back"
              onClick={() => setCustommer({ ...custommer, step: 0 })}
            >
              Retour
            </div>
          </div>
          <div className="ClickandCollect_Custommer_info_title">
            <h2>Informations client</h2>
          </div>
          <div className="ClickandCollect_Custommer_info_form">
            <input
              type="text"
              placeholder="Nom"
              onChange={(e) =>
                setCustommer({ ...custommer, name: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Prénom"
              onChange={(e) =>
                setCustommer({ ...custommer, surname: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Email"
              onChange={(e) =>
                setCustommer({ ...custommer, email: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Téléphone"
              onChange={(e) =>
                setCustommer({ ...custommer, phone: e.target.value })
              }
            />
          </div>

          <div className="ClickandCollect_Custommer_info_time_slot">
            <div className="ClickandCollect_Custommer_info_time_slot_title">
              <h2>Choisissez quand récupérer votre commande</h2>
            </div>
            <div className="ClickandCollect_Custommer_info_time_slot_list">
              {time_slots.map((a_time_slot, index) => {
                return (
                  <div
                    key={index}
                    className={
                      a_time_slot.id === time_slot
                        ? "ClickandCollect_Custommer_info_time_slot_case_selected"
                        : "ClickandCollect_Custommer_info_time_slot_case"
                    }
                    onClick={() => {
                      setTime_slot(a_time_slot.id);
                      console.log(a_time_slot.id);
                    }}
                  >
                    <div className="ClickandCollect_Custommer_info_time_slot_case_day">
                      {/* {On écris la date en toutes lettres ex: Mardi 18 Décembre} */}
                      {a_time_slot.day} {new Date(a_time_slot.date).getDate()}{" "}
                      {new Date(a_time_slot.date).toLocaleString("default", {
                        month: "long",
                      })}
                    </div>
                    {a_time_slot.hours && (
                      <div className="ClickandCollect_Custommer_info_time_slot_case_hour">
                        {a_time_slot.hours}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="ClickandCollect_Custommer_info_validation"
            onClick={verifyCustommer}
          >
            Passer au paiement
          </div>
        </div>
      )}
      {custommer.step === 2 && (
        <div className="ClickandCollect_Payment_zone">
          <div className="ClickandCollect_Payment_title">
            <h2>Paiement</h2>
          </div>
          <div className="ClickandCollect_Payment_back_button_zone">
            <div
              className="ClickandCollect_Payment_back_button"
              onClick={() => setCustommer({ ...custommer, step: 1 })}
            >
              Retour
            </div>
          </div>

          <div className="ClickandCollect_Payment_total">
            Total : {total + totalService} €
          </div>
          <div className="ClickandCollect_Payment_form_taxe">
            Dont frais de service : {totalService} €
          </div>
          <div className="ClickandCollect_Payment_form_taxe">
            Dont TVA : {Math.round((total + totalService) * 0.021 * 100) / 100}{" "}
            €
          </div>

          <div className="ClickandCollect_Payment_Stripe_Zone">
            {clientSecret !== "" ? (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecret }}
              >
                <CheckoutForm
                  custommer={custommer}
                  totalTtc={
                    total +
                    totalService +
                    Math.round((total + totalService) * 0.021 * 100) / 100
                  }
                  total={total + totalService}
                  totalService={totalService}
                  taxe={Math.round((total + totalService) * 0.021 * 100) / 100}
                  clientSecret={clientSecret}
                  cart={cart}
                  setCart={setCart}
                  setCustommer={setCustommer}
                />
              </Elements>
            ) : (
              <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            )}
          </div>
          <div className="ClickandCollect_Payment_form_alert">{formAlert}</div>
        </div>
      )}
      {custommer.step === 3 && (
        <div className="ClickandCollect_Thanks">
          <h2>Merci pour votre commande !</h2>
          <p className="ClickandCollect_Thanks_sentence">
            Vous allez recevoir un mail de confirmation.
          </p>
          <div className="ClickandCollect_Thanks_commande">
            <h3>Commandes :</h3>
            <div className="ClickandCollect_Thanks_commande_id">
              n° {custommer.command_id}
            </div>
            <div className="ClickandCollect_Thanks_commande_detail">
              {cart.map((item, index) => {
                if (item.quantity > 0) {
                  return (
                    <div
                      key={index}
                      className="ClickandCollect_Thanks_commande_item"
                    >
                      {item.quantity} x {item.name} -{" "}
                      {item.price * item.quantity} €
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="ClickandCollect_Thanks_commande_total">
              Total : {total + totalService} €
            </div>
          </div>
          <div
            className="ClickandCollect_Thanks_commande_back_button"
            onClick={() => {
              // lien vers la page racine
              window.location.href = "/";
            }}
          >
            Terminer
          </div>
        </div>
      )}
    </div>
  );
};

export default ClickandCollect;

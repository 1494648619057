import React, { useCallback } from "react";
import "./OnePage.css";
import Menu from "../Components/Menu";
import {
  ArrowDown,
  ArrowDownSquare,
  ArrowLeft,
  ArrowLeftSquare,
  ArrowRightSquare,
  Clock,
  Phone,
  PinMap,
} from "react-bootstrap-icons";
import axios from "axios";

const OnePage = () => {
  const [categories, setCategories] = React.useState([
    {
      id: 1,
      name: "Sushis",
      japanese: "寿司",
      background: "sushis.png",
      sub_category: [
        {
          id: 0,
          name: "Maki",
          description:
            "Les makis sont des rouleaux de riz vinaigré entourés d'une feuille d'algue nori et garnis de poisson cru, de légumes ou d'œufs de poisson.",
          japanese: "巻き",
        },
        {
          id: 2,
          name: "Nigiiri",
          description:
            "Les nigiris sont des boulettes de riz vinaigré surmontées de poisson cru ou de fruits de mer.",
          japanese: "握り",
        },
        {
          id: 3,
          name: "California",
          description:
            "Les california sont des makis inversés, c'est-à-dire que le riz est à l'extérieur et l'algue à l'intérieur.",
          japanese: "カリフォルニア",
        },
        {
          id: 4,
          name: "Sashimi",
          description:
            "Les sashimis sont des tranches de poisson cru servies seules.",
          japanese: "刺身",
        },
        {
          id: 5,
          name: "Spring Roll",
          description:
            "Les spring rolls sont des rouleaux de printemps à base de riz, de poisson cru et de légumes.",
          japanese: "春巻き",
        },
        {
          id: 6,
          name: "Temaki",
          description:
            "Les temakis sont des cônes de riz vinaigré et d'algue nori garnis de poisson cru et de légumes.",
          japanese: "手巻き",
        },
        {
          id: 7,
          name: "Dragon Rolls et Spéciaux",
          description:
            "Les sushis spéciaux sont des créations originales de notre chef.",
          japanese: "スペシャル",
        },
        {
          id: 8,
          name: "Chiraishi",
          description:
            "Les chiraishis sont des bols de riz vinaigré garnis de poisson cru et de légumes.",
          japanese: "ちらし",
        },
      ],
    },
    {
      id: 2,
      name: "Soupe et Ramens",
      japanese: "ラーメン",
      background: "ramens.png",
      sub_category: [
        {
          id: 0,
          name: "Ramen",
          description:
            "Les ramens sont des nouilles chinoises servies dans un bouillon à base de viande ou de poisson.",
          japanese: "ラーメン",
        },
        {
          id: 2,
          name: "Soupe",
          description:
            "Les soupes sont des bouillons de viande ou de poisson servis avec des légumes et des nouilles.",
          japanese: "スープ",
        },
      ],
    },
    {
      id: 3,
      name: "Côté Chaud",
      japanese: "熱い",
      background: "hot.png",
      sub_category: [
        {
          id: 0,
          name: "Tonkatsu",
          description:
            "Les tonkatsus sont des escalopes de porc / poulet panées accompagnées de riz.",
          japanese: "とんかつ",
        },
        {
          id: 2,
          name: "Woks",
          description:
            "Nos woks sont tous à base de courgettes, carottes, oignons, gingembre frais, champignons noirs et chou chinois.",
          japanese: "炒め物",
        },
        {
          id: 3,
          name: "Crispy et Beignets",
          description:
            "Les Cripsy et Beignets sont des plats frits à base de poulet, crevettes ou légumes.",
          japanese: "クリスピー",
        },
        {
          id: 4,
          name: "Gyozas",
          description: "Les gyozas sont des raviolis japonais.",
          japanese: "餃子",
        },
        {
          id: 5,
          name: "Nems",
          description: "Les nems sont des rouleaux de printemps frits.",
          japanese: "春巻き",
        },
        {
          id: 6,
          name: "Yakitoris",
          description:
            "Les yakitoris sont des brochettes de poulet, de bœuf ou de poisson.",
          japanese: "焼き鳥",
        },
      ],
    },
    {
      id: 4,
      name: "Accompagnements",
      japanese: "おかず",
      background: "accompagnements.png",
      sub_category: [
        {
          id: 0,
          name: "Edamame",
          description:
            "Les edamames sont des fèves de soja salées à déguster à l'apéritif.",
          japanese: "枝豆",
        },
        {
          id: 2,
          name: "Salades",
          description: "Nos salades sont toutes à base de chou chinois.",
          japanese: "サラダ",
        },
        {
          id: 3,
          name: "Riz",
          description: "Nos riz sont tous à base de riz japonais.",
          japanese: "ご飯",
        },
        {
          id: 4,
          name: "Sauces",
          description: "Nos sauces sont toutes faites maison.",
          japanese: "ソース",
        },
      ],
    },
    {
      id: 5,
      name: "Menu Enfant",
      japanese: "子供",
      background: "kids.png",
      sub_category: [
        {
          id: 0,
          name: "Menu Enfant",
          description: "Nos menus enfants sont servis avec un dessert.",
          japanese: "子供",
        },
      ],
    },
    {
      id: 6,
      name: "Desserts",
      japanese: "デザート",
      background: "mochi.png",
      sub_category: [
        {
          id: 0,
          name: "Mochis",
          description:
            "Les mochis sont des gâteaux de riz gluant fourrés à déguster glacés.",
          japanese: "餅",
        },
        {
          id: 2,
          name: "Autres",
          description: "Les autres desserts.",
          japanese: "その他",
        },
      ],
    },
    {
      id: 7,
      name: "Boissons",
      japanese: "飲み物",
      background: "drinks.png",
      sub_category: [
        {
          id: 0,
          name: "Softs",
          description: "Tous nos softs.",
          japanese: "ソーダ",
        },
        {
          id: 2,
          name: "Bières",
          description: "Toutes nos bières. D'ici et d'asie",
          japanese: "ビール",
        },
        {
          id: 3,
          name: "Vins",
          description: "Tous nos vins.",
          japanese: "ワイン",
        },
        {
          id: 6,
          name: "Cocktails",
          description: "Nos cocktails tout prêt.",
          japanese: "カクテル",
        },
      ],
    },
  ]);
  const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

  const [menu, setMenu] = React.useState([
    {
      id: 1,
      category: "SUSHIS",
      japaneseCategory: "寿司",
      background: "sushis.png",
      status: false,
      subCategories: [
        {
          title: "Nigiri (x2)",
          japaneseTitle: "握り",
          status: false,
          description:
            "Les makis sont des rouleaux de riz vinaigré entourés d'une feuille d'algue nori et garnis de poisson cru, de légumes ou d'œufs de poisson.",
          items: [
            {
              ref: "N1",
              title: "Avocat",
              price: "3,90€",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      category: "RAMENS",
      japaneseCategory: "ラーメン",
      status: false,
      background: "ramens.png",
      subCategories: [
        {
          title: "Ramen",
          japaneseTitle: "ラーメン",
          status: false,
          description:
            "Les ramens sont des nouilles chinoises servies dans un bouillon à base de viande ou de poisson.",
          items: [
            {
              ref: "R1",
              title: "Poulet",
              price: "8,90€",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      category: "DESSERTS",
      japaneseCategory: "デザート",
      status: false,
      background: "mochi.png",
      subCategories: [
        {
          title: "Mochi",
          japaneseTitle: "餅",
          status: false,
          description: "Gâteau de riz gluant fourré à déguster glacé.",
          items: [
            {
              ref: "D1",
              title: "Fraise",
              price: "3,50€",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      category: "BOISSONS",
      japaneseCategory: "飲み物",
      status: false,
      background: "drinks.png",
      subCategories: [
        {
          title: "Soda",
          japaneseTitle: "ソーダ",
          status: false,
          description: "Boisson gazeuse.",
          items: [
            {
              ref: "B1",
              title: "Coca-Cola",
              price: "2,50€",
            },
          ],
        },
      ],
    },
  ]);
  const switchCategory = (id) => {
    const newMenu = menu.map((item) => {
      if (item.id === id) {
        item.status = !item.status;
      }
      return item;
    });
    setMenu(newMenu);
  };
  const command_action = () => {
    // s'il est plus de 16h30
    const date = new Date();
    const hours = date.getHours();

    window.location.href = "tel:+33495600347";

    // if (hours >= 16) {
    //   // On redirige vers le numéro de téléphone
    //   window.location.href = "tel:+33495600347";
    // } else {
    //   // On redirige vers le formulaire de commande
    //   window.location.href = "/command";
    // }
  };
  const load_categories = React.useCallback(async () => {
    const response = await axios.post(
      "https://sushinari.fr/api/categories",
      {}
    );
    if (response.data.success && response.data.categories) {
      setCategories(
        response.data.categories.map((category) => ({
          id: category.id,
          name: category.name,
          japanese: category.japanese,
          sub_category:
            category.sub_categories.length > 0
              ? category.sub_categories.map((subcategory) => ({
                  id: subcategory.id,
                  name: subcategory.name,
                  description: subcategory.description,
                  japanese: subcategory.japanese,
                }))
              : [],
          opened: 0,
        }))
      );
    } else {
      console.log("Erreur de chargement des catégories");
    }
  }, []);
  const load_menu = useCallback(async () => {
    const response = await axios.post("https://sushinari.fr/api/articles", {
      params: {
        type: "menu_soir",
      },
    });

    if (response.data.success) {
      const newMenu = [];
      categories.map((category) => {
        const newCategory = {
          id: category.id,
          category: category.name,
          japaneseCategory: category.japanese,
          status: false,
          background: category.background,
          subCategories: category.sub_category.map((subCategory) => {
            console.log("subCategory", subCategory);
            return {
              title: subCategory ? subCategory.name : "N/A",
              status: false,
              japaneseTitle: subCategory ? subCategory.japanese : "N/A",
              description: subCategory ? subCategory.description : "N/A",
              items: response.data.articles
                .map((article) => {
                  if (
                    subCategory.id === parseInt(article.sub_category) &&
                    article.category === category.id
                  ) {
                    console.log("article", article);
                    return {
                      ref: article.ref || "N/A",
                      title: article.name,
                      price: article.price,
                      image: article.picture,
                      description: article.description,
                    };
                  }
                })
                .filter((item) => item !== undefined && item !== null),
            };
          }),
        };
        newMenu.push(newCategory);

        setMenu(newMenu);
      });
    }
  }, [categories]);

  React.useEffect(() => {
    load_categories();
  }, [load_categories]);
  React.useEffect(() => {
    load_menu();
  }, [categories, load_menu]);

  return (
    <div className="OnePage">
      <Menu />
      <div className="OnePage_video_screen">
        <div className="OnePage_video_background">
          <div className="OnePage_Title">
            <div className="OnePage_Title_left">いなり寿司</div>
            <div className="OnePage_Title_right">
              <p className="OnePage_japanese_title">稲荷鮨</p>
              <h1>Sush'Inari</h1>
            </div>
          </div>
          <div className="OnePage_Title_bottom">
            <p>SUSHI SHOP - ASIAN FOOD</p>
          </div>
          <div className="OnePage_video_action_button" onClick={command_action}>
            COMMANDER | 注文
          </div>
        </div>
      </div>
      <div className="OnePage_menu" id="menu">
        {menu.map((item) => (
          <div
            key={item.id}
            className="OnePage_menu_category"
            style={{
              backgroundImage: `url('../../images/${item.background}')`,
              backgroundColor: `rgb(255, 141, 115, ${item.id * 0.1})`,
            }}
          >
            <div
              className="OnePage_menu_category_title"
              onClick={() => switchCategory(item.id)}
              style={
                item.status ? { height: "fit-content" } : { height: "20vh" }
              }
            >
              {item.category}
              <span className="OnePage_menu_category_title_japanese">
                {item.japaneseCategory}
              </span>
            </div>
            <div
              className={
                item.status
                  ? "OnePage_menu_category_section"
                  : "OnePage_menu_category_section_hidden"
              }
            >
              {item.subCategories.map((subCategory) => (
                <div className="OnePage_menu_sub_category">
                  <div
                    className="OnePage_menu_sub_category_title"
                    onClick={() => {
                      const newMenu = menu.map((menu) => {
                        if (menu.id === item.id) {
                          const newSubCategories = menu.subCategories.map(
                            (sub) => {
                              if (sub.title === subCategory.title) {
                                sub.status = !sub.status;
                              }
                              return sub;
                            }
                          );
                          menu.subCategories = newSubCategories;
                        }
                        return menu;
                      });
                      setMenu(newMenu);
                    }}
                  >
                    <span className="OnePage_menu_sub_category_title_text">
                      {subCategory.title}
                      <span className="OnePage_menu_sub_category_title_japanese">
                        {subCategory.japaneseTitle}
                      </span>
                    </span>
                    <span className="OnePage_menu_sub_category_title_arrow">
                      {subCategory.status ? (
                        <ArrowDownSquare size={24} color="#c60000" />
                      ) : (
                        <ArrowRightSquare size={24} color="#c60000" />
                      )}
                    </span>
                  </div>
                  {subCategory.status && (
                    <>
                      <div className="OnePage_menu_sub_category_description">
                        {subCategory.description}
                      </div>
                      {console.log("subCategory.items", subCategory.items)}
                      {subCategory.items.map((item) => {
                        if (item === null) return null;
                        if (!item.image) {
                          return (
                            <div className="OnePage_menu_category_section_item">
                              {console.log("ITEM", item)}
                              <div className="OnePage_menu_category_section_item_ref">
                                {item.ref}
                              </div>
                              <div className="OnePage_menu_category_section_item_title">
                                {item.title}
                              </div>
                              <div className="OnePage_menu_category_section_item_price">
                                {item.price + " €"}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="OnePage_menu_category_section_item_with_image">
                              <div
                                className="OnePage_menu_category_section_item_image"
                                style={{
                                  backgroundImage: `url('https://sushinari.fr/uploads/${item.image}')`,
                                }}
                              ></div>
                              <div className="OnePage_menu_category_pic_section_title_zone">
                                <div className="OnePage_menu_category_section_item_ref">
                                  {item.ref}
                                </div>
                                <div className="OnePage_menu_category_section_item_title">
                                  {item.title}
                                </div>
                                <div className="OnePage_menu_category_section_item_description">
                                  {item.description}
                                </div>
                              </div>
                              <div className="OnePage_menu_category_pic_section_item_price">
                                {item.price + " €"}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="OnePage_wheretofindus">
        <div className="OnePage_wheretofindus_background">
          <div className="OnePage_wheretofindus_title">
            Où nous trouver ?<br />
            <span className="OnePage_wheretofindus_title_japanese">
              ここはどこ
            </span>
          </div>
        </div>
        <div className="OnePage_wheretofindus_zone" id="map">
          <div className="OnePage_wheretofindus_address">
            <p className="OnePage_wheretofindus_address_title">
              <PinMap size={20} color="#c60000" />
              Adresse
            </p>
            <p className="OnePage_wheretofindus_address_content">
              Centre commerciale Espace covasina, 20240 Ventiseri
            </p>
          </div>
          <div className="OnePage_wheretofindus_map">
            <gmp-map
              center="41.92592363039696, 9.390129312032087"
              zoom="15"
              map-id="DEMO_MAP_ID"
              style={{ height: "100%" }}
            >
              <gmp-advanced-marker
                position="41.92592363039696, 9.390129312032087"
                title="Sush'Inari, Ventiseri"
              ></gmp-advanced-marker>
            </gmp-map>
          </div>
          <div className="OnePage_wheretofindus_contact">
            <p className="OnePage_wheretofindus_address_title">
              <Phone size={20} color="#c60000" />
              Téléphone
            </p>
            <a
              className="OnePage_wheretofindus_phone_button"
              href="tel:+33495600347"
              id="phone"
            >
              04 95 60 03 47
            </a>
          </div>
          <div className="OnePage_wheretofindus_hours">
            <p className="OnePage_wheretofindus_address_title">
              <Clock size={20} color="#c60000" />
              Horaires
            </p>
            <p className="OnePage_wheretofindus_address_content">
              Du Lundi au Samedi : 11h - 14h / 17h - 22h
            </p>
            <p className="OnePage_wheretofindus_address_content">
              Fermé le dimanche
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnePage;

import logo from "./logo.svg";
import "./App.css";
import OnePage from "./Navigation/OnePage";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Navigation/Dashboard";
import Login from "./Navigation/Login";
import ClickandCollect from "./Navigation/ClickandCollect";
import CreatePassword from "./Navigation/CreatePassword";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<OnePage />} />
        <Route path="/clickandcollect" element={<ClickandCollect />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create_password/:id" element={<CreatePassword />} />
      </Routes>
    </div>
  );
}

export default App;

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    custommer,
    setCustommer,
    totalTtc,
    total,
    totalService,
    taxe,
    clientSecret,
    cart,
    setCart,
  } = props;
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("clientSecret", clientSecret);
    await stripe
      .confirmPaymentIntent(clientSecret, elements.getElement(PaymentElement), {
        payment_method_data: {
          type: "Card",
          billing_details: {
            name: custommer.name + " " + custommer.surname,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          console.log("error", result.error.message);
        } else if (result.paymentIntent.status === "succeeded") {
          console.log("paiement réussi");
          setCustommer({
            ...custommer,
            step: 3,
          });
        }
      });
  };

  return (
    <form>
      <PaymentElement />
      <button type="submit" disabled={!stripe} onClick={handleSubmit}>
        Submit
      </button>
    </form>
  );
};

export default CheckoutForm;

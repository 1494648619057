import React from "react";
import "./Menu.css";
import { X } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";

const Menu = () => {
  const [menuToggle, setMenuToggle] = React.useState(false);
  const page = useLocation().pathname;

  const scrollSmoothTo = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="Menu">
        <div className="menu_space"></div>
        <div className="logo"></div>
        <div className="menu_burger" onClick={() => setMenuToggle(!menuToggle)}>
          <div className="menu_burger_japanese">
            メ<br />
            ニ<br />
            ュ<br />ー
          </div>
          <div className="menu_burger_title">
            M<br />
            e<br />
            n<br />u
          </div>
        </div>
      </div>
      <div className={menuToggle ? "menu_open" : "menu_close"}>
        <div className="menu_open_top">
          <div className="menu_open_top_japanese">
            メ<br />
            ニ<br />
            ュ<br />ー
          </div>
          <div
            className="menu_open_top_cross"
            onClick={() => setMenuToggle(!menuToggle)}
          >
            <X size={40} />
          </div>
        </div>
        <div className="menu_open_middle">
          <div
            className="menu_open_line"
            onClick={() => {
              setMenuToggle(!menuToggle);
              scrollSmoothTo("menu");
            }}
          >
            NOTRE CARTE
          </div>
          <div
            className="menu_open_line"
            onClick={() => {
              setMenuToggle(!menuToggle);
              scrollSmoothTo("map");
            }}
          >
            NOUS TROUVER
          </div>
          <div
            className="menu_open_line"
            onClick={() => {
              setMenuToggle(!menuToggle);
              scrollSmoothTo("phone");
            }}
          >
            NOUS CONTACTER
          </div>
          <a className="menu_open_line" href="/dashboard">
            DASHBOARD
          </a>
        </div>
        <div className="menu_open_bottom">
          <div className="menu_open_bottom_title">
            M<br />
            e<br />
            n<br />u
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
